import React, { useEffect, useState } from "react";
import axiosInstance from "../../axiosInstance";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Box,
  Button,
  Typography,
  InputLabel,
  FormControl,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  Input,
  MenuItem,
  Modal,
  Fade,
  Backdrop,
} from "@mui/material";
import QrScanner from "react-qr-scanner";
import { format } from "date-fns";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
const baseUrl = process.env.REACT_APP_BASE_URL;

const InvitadosTotal = () => {
  const [invitados, setInvitados] = useState([]);
  const [users, setUsers] = useState([]);
  const [lotes, setLotes] = useState([]);
  const [entradas, setEntradas] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentAction, setCurrentAction] = useState({
    id: "",
    lote: "",
    action: "",
    type: "",
    token: "",
    invitacionToken: "",
  });
  const [isScanning, setIsScanning] = useState(false);
  const [selectedInvitado, setSelectedInvitado] = useState(null); // Para almacenar los detalles del invitado seleccionado
  const [openModal, setOpenModal] = useState(false); // Para controlar la visibilidad del modal
  const [order, setOrder] = useState("asc"); // Puede ser "asc" o "desc"
  const [orderBy, setOrderBy] = useState("createdAt"); // La columna por la que se ordena

  const navigate = useNavigate();
  useEffect(() => {
    fetchInvitados();
    fetchUsers();
    fetchLotes();
    fetchEntradas();
  }, []);

  const fetchInvitados = async () => {
    const response = await axiosInstance.get(`${baseUrl}/invitados/invitadosPortero/getAll`);
    setInvitados(response.data);
  };

  const fetchUsers = async () => {
    const response = await axiosInstance.get(`${baseUrl}/auth`);
    setUsers(response.data);
  };

  const fetchLotes = async () => {
    const response = await axiosInstance.get(`${baseUrl}/lotes`);
    setLotes(response.data);
  };

  const fetchEntradas = async () => {
    const response = await axiosInstance.get(`${baseUrl}/lotes/entradas/getAll`);
    setEntradas(response.data);
  };

  const handleOpenEntry = () => {
    setCurrentAction({ id: "", lote: "", action: "", token: "" });
    setOpen(true);
  };

  const handleRedirectToHistorial = () => {
    navigate("/portero/inquilinos");
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (orderBy === "createdAt") {
      return new Date(b.createdAt) - new Date(a.createdAt); // Compara las fechas
    }
    return 0; // Puedes agregar más comparadores si lo necesitas
  };

  const comparator = (a, b) => {
    if (order === "desc") {
      return descendingComparator(a, b, orderBy);
    } else {
      return -descendingComparator(a, b, orderBy);
    }
  };

  const sortedEntradas = stableSort(entradas, comparator);

  const handleCloseEntry = () => {
    setOpen(false);
    setIsScanning(false);
  };

  const handleSaveEntry = async () => {
    const entryData = {
      id: currentAction.id,
      lote: currentAction.lote,
      action: currentAction.action,
      token: currentAction.token,
      invitacionToken: currentAction.invitacionToken,
    };

    try {
      const response = await axiosInstance.post(`${baseUrl}/invitados/validateEntry`, entryData);
      toast.success(response.data.message || "Acción registrada con éxito");
      fetchEntradas();
      handleCloseEntry();
    } catch (error) {
      toast.error(error.response?.data?.error || "Error al registrar la acción");
    }
  };

  const handleScan = (data) => {
    if (data) {
      const decodedData = JSON.parse(data.text);
      setCurrentAction((prevState) => ({
        ...prevState,
        token: decodedData.token,
        lote: decodedData.lote ? decodedData.lote : null,
        invitacionToken: decodedData.invitacionToken ? decodedData.invitacionToken : null,
      }));
      setIsScanning(false);
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleViewDetails = (invitado) => {
    setSelectedInvitado(invitado);
    setOpenModal(true); // Abrir el modal
  };
  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedInvitado(null); // Limpiar los datos al cerrar el modal
  };
  const paginatedEntradas = entradas.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <Box sx={{ padding: 3 }}>
      <ToastContainer />
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h4">Historial de Acciones</Typography>
        <Button variant="contained" color="primary" onClick={handleRedirectToHistorial}>
          Historial de Inquilinos
        </Button>
        <Button variant="contained" color="primary" onClick={handleOpenEntry}>
          Agregar Entrada/Salida
        </Button>
      </Box>
      <TableContainer component={Paper} sx={{ width: "100%", overflowX: "auto" }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontSize: "20px" }} align="left">
                Nombre
              </TableCell>
              <TableCell sx={{ fontSize: "20px" }} align="left">
                CI
              </TableCell>
              <TableCell sx={{ fontSize: "20px" }} align="left">
                Lote
              </TableCell>
              <TableCell sx={{ fontSize: "20px" }} align="left">
                Accion
              </TableCell>
              <TableCell
                sx={{ fontSize: "20px" }}
                align="left"
                sortDirection={orderBy === "createdAt" ? order : false}
              >
                <span
                  onClick={() => handleRequestSort("createdAt")}
                  style={{ cursor: "pointer", color: "blue" }}
                >
                  Fecha {order === "asc" ? "↑" : "↓"}
                </span>
              </TableCell>

              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedEntradas
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((entrada) => (
                <TableRow key={entrada.id}>
                  <TableCell>{entrada.nombre}</TableCell>
                  <TableCell>{entrada.cedula_identidad}</TableCell>
                  <TableCell align="left">
                    Lote: {entrada.lote} Manzana: {entrada.manzana}
                  </TableCell>
                  <TableCell align="left">{entrada.action}</TableCell>
                  <TableCell align="left">
                    {format(new Date(entrada.createdAt), "dd/MM/yyyy HH:mm:ss")}
                  </TableCell>
                  <TableCell>
                    <Button variant="outlined" onClick={() => handleViewDetails(entrada)}>
                      Ver Detalles
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={entradas.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[10, 25, 50, 100]}
          labelRowsPerPage="Mostrar"
        />
      </TableContainer>

      <Dialog maxWidth="md" fullWidth open={open} onClose={handleCloseEntry}>
        <DialogTitle>Agregar Entrada/Salida</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="dense">
            <InputLabel id="action-select-label">Seleccionar Acción</InputLabel>
            <Select
              labelId="action-select-label"
              value={currentAction.action}
              onChange={(e) => setCurrentAction({ ...currentAction, action: e.target.value })}
              input={<Input />}
            >
              <MenuItem value="Entrada">Entrada</MenuItem>
              <MenuItem value="Salida">Salida</MenuItem>
            </Select>
          </FormControl>

          {isScanning ? (
            <QrScanner
              delay={300}
              onError={handleError}
              onScan={handleScan}
              style={{ width: "100%" }}
              constraints={{
                video: {
                  facingMode: { exact: "environment" }, // Usa la cámara trasera
                },
              }}
            />
          ) : (
            <Button onClick={() => setIsScanning(true)} variant="contained" color="primary">
              Escanear QR
            </Button>
          )}

          {currentAction && (
            <div style={{ marginTop: "20px", color: "green" }}>
              <h4>{currentAction.token ? "Los datos se escanearon correctamente" : ""}</h4>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEntry} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleSaveEntry} color="primary">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              borderRadius: 2,
              boxShadow: 24,
              padding: 2,
              maxHeight: "80vh",
              overflowY: "auto",
            }}
          >
            {selectedInvitado && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <Typography variant="h5" gutterBottom>
                  Detalles del Invitado
                </Typography>
                {selectedInvitado.foto && (
                  <img
                    src={`${baseUrl}/${selectedInvitado.foto}`}
                    alt="Foto del invitado"
                    style={{
                      width: "150px", // Ajusta el tamaño aquí
                      height: "150px", // Asegurando que sea cuadrada
                      objectFit: "cover", // Para recortar la imagen si es necesario
                      borderRadius: "8px", // Bordes redondeados
                      marginBottom: 10,
                    }}
                  />
                )}
                <Typography variant="body1">
                  <strong>Nombre:</strong> {selectedInvitado.nombre}
                </Typography>
                <Typography variant="body1">
                  <strong>CI:</strong> {selectedInvitado.cedula_identidad}
                </Typography>
                <Typography variant="body1">
                  <strong>Género:</strong> {selectedInvitado.genero}
                </Typography>
                <Typography variant="body1">
                  <strong>Email:</strong> {selectedInvitado.email}
                </Typography>

                <Typography variant="body1">
                  <strong>Fecha de Creación:</strong>{" "}
                  {new Date(selectedInvitado.createdAt).toLocaleString()}
                </Typography>
              </Box>
            )}
            {/* Botón Cerrar alineado a la derecha */}
            <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: 2 }}>
              <Button variant="contained" color="secondary" onClick={handleCloseModal}>
                Cerrar
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
};

export default InvitadosTotal;
