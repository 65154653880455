// components/AdminContacts.jsx
import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
  Grid,
  Card,
  CardContent,
  IconButton,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { Add, Edit, Delete } from "@mui/icons-material";
import axiosInstance from "../../axiosInstance";

const AdminContacts = () => {
  const [contacts, setContacts] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    type: "otros",
  });
  const [editMode, setEditMode] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [contactToDelete, setContactToDelete] = useState(null);

  useEffect(() => {
    fetchContacts();
  }, []);

  const fetchContacts = async () => {
    const response = await axiosInstance.get("/contacts");
    setContacts(response.data.data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (editMode && !formData.id) {
      console.error("ID no definido para edición");
      return;
    }

    try {
      if (editMode) {
        await axiosInstance.put(`/contacts/${formData.id}`, formData);
      } else {
        await axiosInstance.post("/contacts", formData);
      }
      fetchContacts();
      setFormData({ name: "", phone: "", email: "", type: "otros" });
      setEditMode(false);
    } catch (error) {
      console.error("Error al guardar:", error);
    }
  };

  const handleDelete = async (id) => {
    await axiosInstance.delete(`/contacts/${id}`);
    fetchContacts();
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Gestión de Contactos
      </Typography>
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <TextField
                  label="Nombre"
                  fullWidth
                  value={formData.name}
                  onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                  required
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  label="Teléfono"
                  fullWidth
                  value={formData.phone}
                  onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                  required
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  label="Email"
                  type="email"
                  fullWidth
                  value={formData.email}
                  onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <Select
                  fullWidth
                  value={formData.type}
                  onChange={(e) => setFormData({ ...formData, type: e.target.value })}
                >
                  <MenuItem value="mantenimiento">Mantenimiento</MenuItem>
                  <MenuItem value="emergencia">Emergencia</MenuItem>
                  <MenuItem value="administrativo">Administrativo</MenuItem>
                  <MenuItem value="otros">Otros</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12}>
                <Button type="submit" variant="contained" startIcon={<Add />}>
                  {editMode ? "Actualizar" : "Agregar"}
                </Button>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
      <Grid container spacing={3}>
        {contacts.map((contact) => (
          <Grid item xs={12} md={6} key={contact.id}>
            <Card>
              <CardContent>
                <Box display="flex" justifyContent="space-between">
                  <div>
                    <Typography variant="h6">{contact.name}</Typography>
                    <Typography>{contact.phone}</Typography>
                    <Typography color="text.secondary">{contact.email}</Typography>
                    <Chip label={contact.type} sx={{ mt: 1 }} />
                  </div>
                  <div>
                    <IconButton
                      onClick={() => {
                        setFormData(contact);
                        setEditMode(true);
                      }}
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        setContactToDelete(contact);
                        setDeleteDialogOpen(true);
                      }}
                    >
                      <Delete color="error" />
                    </IconButton>
                  </div>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <DialogTitle>Confirmar eliminación</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro de eliminar el contacto de {contactToDelete?.name}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancelar</Button>
          <Button
            onClick={() => {
              handleDelete(contactToDelete?.id);
              setDeleteDialogOpen(false);
            }}
            color="error"
          >
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AdminContacts;
