import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Chip,
} from "@mui/material";
import { Edit, Visibility } from "@mui/icons-material";
import axiosInstance from "../../axiosInstance";
import { useNavigate } from "react-router-dom";
const baseUrl = process.env.REACT_APP_BASE_URL;

const OwnerTenantList = () => {
  const [tenants, setTenants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedTenant, setSelectedTenant] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    fetchTenants();
  }, []);

  const fetchTenants = async () => {
    try {
      const response = await axiosInstance.get("/tenants/owner");
      setTenants(response.data);
    } catch (error) {
      console.error("Error fetching tenants:", error);
    }
    setLoading(false);
  };

  return (
    <Box sx={{ p: 4 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Typography variant="h4">Mis Inquilinos</Typography>
        <Button variant="contained" color="primary" onClick={() => navigate("/agregar-inquilino")}>
          Agregar Inquilino
        </Button>
      </Box>

      {loading ? (
        <CircularProgress sx={{ display: "block", mx: "auto", mt: 4 }} />
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <b>Nombre</b>
                </TableCell>
                <TableCell>
                  <b>Documento</b>
                </TableCell>
                <TableCell>
                  <b>Email</b>
                </TableCell>
                <TableCell>
                  <b>Teléfono</b>
                </TableCell>
                <TableCell>
                  <b>Lote</b>
                </TableCell>
                <TableCell>
                  <b>Estado</b>
                </TableCell>
                <TableCell>
                  <b>Acciones</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tenants.map((tenant) => (
                <TableRow key={tenant.id}>
                  <TableCell>{tenant.nombre}</TableCell>
                  <TableCell>
                    {tenant.tipoDocumento} - {tenant.numeroDocumento}
                  </TableCell>
                  <TableCell>{tenant.email}</TableCell>
                  <TableCell>{tenant.telefono}</TableCell>
                  <TableCell>
                    Lote {tenant.Lote?.lote} - Mza {tenant.Lote?.manzana}
                  </TableCell>
                  <TableCell>
                    <Chip
                      label={tenant.estado}
                      color={
                        tenant.estado === "pendiente"
                          ? "warning"
                          : tenant.estado === "aprobado"
                          ? "success"
                          : "error"
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      startIcon={<Visibility />}
                      onClick={() => {
                        setSelectedTenant(tenant);
                        setOpenDialog(true);
                      }}
                    >
                      Ver Detalles
                    </Button>
                    {tenant.estado === "rechazado" && (
                      <Button
                        variant="contained"
                        startIcon={<Edit />}
                        color="primary"
                        onClick={() => (window.location.href = `/editar-inquilino/${tenant.id}`)}
                      >
                        Editar
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)} fullWidth maxWidth="sm">
        <DialogTitle>Detalles del Inquilino</DialogTitle>
        <DialogContent>
          {selectedTenant && (
            <>
              <Typography>
                <b>Nombre:</b> {selectedTenant.nombre}
              </Typography>
              <Typography>
                <b>Documento:</b> {selectedTenant.tipoDocumento} - {selectedTenant.numeroDocumento}
              </Typography>
              <Typography>
                <b>Email:</b> {selectedTenant.email}
              </Typography>
              <Typography>
                <b>Teléfono:</b> {selectedTenant.telefono}
              </Typography>
              <Typography>
                <b>Lote:</b> {selectedTenant.Lote?.lote} - Mza {selectedTenant.Lote?.manzana}
              </Typography>
              <Typography>
                <b>Estado:</b> {selectedTenant.estado}
              </Typography>
              {selectedTenant.estado === "rechazado" && (
                <Typography color="error">
                  <b>Motivo del rechazo:</b> {selectedTenant.comentarioAdmin || "Sin comentario"}
                </Typography>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cerrar</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default OwnerTenantList;
