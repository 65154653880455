// components/UserContacts.jsx
import React, { useState, useEffect } from "react";
import { Box, Typography, Grid, Card, CardContent, Chip } from "@mui/material";
import { Phone, Email, Construction, Business } from "@mui/icons-material";
import { Warning } from "@mui/icons-material";
import axiosInstance from "../../axiosInstance";

const UserContacts = () => {
  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    const fetchContacts = async () => {
      const response = await axiosInstance.get("/contacts");
      setContacts(response.data.data);
    };
    fetchContacts();
  }, []);

  const getIcon = (type) => {
    switch (type) {
      case "mantenimiento":
        return <Construction fontSize="large" />;
      case "emergencia":
        return <Warning fontSize="large" />;
      case "administrativo":
        return <Business fontSize="large" />;
      default:
        return <Phone fontSize="large" />;
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Contactos de Emergencia
      </Typography>

      <Grid container spacing={3}>
        {contacts.map((contact) => (
          <Grid item xs={12} md={6} lg={4} key={contact.id}>
            <Card sx={{ height: "100%" }}>
              <CardContent>
                <Box display="flex" alignItems="center" gap={2}>
                  {getIcon(contact.type)}
                  <div>
                    <Typography variant="h6">{contact.name}</Typography>
                    <Typography>{contact.phone}</Typography>
                    {contact.email && (
                      <Typography color="text.secondary">
                        <Email sx={{ verticalAlign: "middle", mr: 1 }} />
                        {contact.email}
                      </Typography>
                    )}
                    <Chip label={contact.type} sx={{ mt: 1 }} />
                  </div>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default UserContacts;
