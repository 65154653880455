import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Grid,
  Select,
  MenuItem,
  CircularProgress,
  Typography,
  Chip,
} from "@mui/material";
import { SportsSoccer, Schedule } from "@mui/icons-material";
import axiosInstance from "../../../axiosInstance";
import moment from "moment";

const AdminReservationModal = ({ open, onClose, courtId, date }) => {
  const [owners, setOwners] = useState([]);
  const [selectedOwner, setSelectedOwner] = useState("");
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [availableSlots, setAvailableSlots] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Cargar propietarios y slots disponibles
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        // Obtener propietarios
        const ownersResponse = await axiosInstance.get("/auth/getAllPropietarios");
        setOwners(ownersResponse.data);

        // Obtener slots disponibles
        const slotsResponse = await axiosInstance.get("/reservations/available", {
          params: { date: date.format("YYYY-MM-DD"), courtId },
        });
        setAvailableSlots(slotsResponse.data);
      } catch (error) {
        setError("Error cargando datos");
      } finally {
        setLoading(false);
      }
    };

    if (open) fetchData();
  }, [open, courtId, date]);

  const handleSlotSelection = (slot) => {
    if (!slot.available) {
      setError("Este horario no está disponible");
      return;
    }

    const startDate = moment(`${date.format("YYYY-MM-DD")}T${slot.start}`);
    const endDate = moment(`${date.format("YYYY-MM-DD")}T${slot.end}`);

    if (!startDate.isValid() || !endDate.isValid()) {
      setError("Horario inválido");
      return;
    }

    setSelectedSlot({
      start: startDate,
      end: endDate,
    });
  };

  const handleReservation = async () => {
    try {
      if (!selectedSlot?.start?.isValid() || !selectedSlot?.end?.isValid() || !selectedOwner) {
        setError("Selección inválida");
        return;
      }

      const reservationData = {
        courtId,
        date: date.format("YYYY-MM-DD"),
        startTime: selectedSlot.start.format("HH:mm:ss"),
        endTime: selectedSlot.end.format("HH:mm:ss"),
        userId: selectedOwner,
      };

      await axiosInstance.post("/reservations/admin/reserva", reservationData);
      onClose(); // Cerrar el modal después de la reserva
      setError(null);
    } catch (error) {
      setError(error.response?.data?.error || "Error al crear reserva");
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Reservar Cancha</DialogTitle>
      <DialogContent>
        {loading ? (
          <CircularProgress sx={{ display: "block", margin: "2rem auto" }} />
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Select
                value={selectedOwner}
                onChange={(e) => setSelectedOwner(e.target.value)}
                fullWidth
                variant="outlined"
                displayEmpty
              >
                <MenuItem value="" disabled>
                  Seleccionar propietario
                </MenuItem>
                {owners.map((owner) => (
                  <MenuItem key={owner.id} value={owner.id}>
                    {owner.username} ({owner.email})
                  </MenuItem>
                ))}
              </Select>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Horarios Disponibles
              </Typography>
              <Grid container spacing={2}>
                {availableSlots.map((slot, index) => (
                  <Grid item xs={6} sm={4} md={3} key={index}>
                    <Chip
                      label={`${slot.start} - ${slot.end}`}
                      onClick={() => handleSlotSelection(slot)}
                      color={slot.available ? "primary" : "default"}
                      disabled={!slot.available}
                      sx={{ width: "100%", cursor: "pointer" }}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>

            {selectedSlot && (
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Detalles de la Reserva
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      label="Hora Inicio"
                      value={selectedSlot.start.format("LT")}
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Hora Fin"
                      value={selectedSlot.end.format("LT")}
                      fullWidth
                      disabled
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        )}
        {error && (
          <Typography color="error" sx={{ mt: 2 }}>
            {error}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button
          onClick={handleReservation}
          variant="contained"
          color="primary"
          disabled={!selectedSlot || !selectedOwner}
        >
          Confirmar Reserva
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AdminReservationModal;
