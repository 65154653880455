import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

const DocumentTypeModal = ({ open, onClose, onSubmit }) => {
  const [tipoDocumento, setTipoDocumento] = useState("");

  const handleSubmit = () => {
    if (tipoDocumento) {
      onSubmit(tipoDocumento);
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Seleccione el tipo de documento</DialogTitle>
      <DialogContent>
        <FormControl fullWidth margin="dense">
          <InputLabel>Tipo de Documento</InputLabel>
          <Select value={tipoDocumento} onChange={(e) => setTipoDocumento(e.target.value)}>
            <MenuItem value="selfie">Selfie</MenuItem>
            <MenuItem value="fotocopia">Fotocopia de Documento</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button onClick={handleSubmit} variant="contained">
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DocumentTypeModal;
