import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Grid,
  Chip,
} from "@mui/material";
import { CheckCircle, Cancel, Visibility, People } from "@mui/icons-material";
import axiosInstance from "../../axiosInstance"; // Asegúrate de que la ruta es correcta
import { Print, QrCode } from "@mui/icons-material";
import QRCode from "qrcode";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import jsPDF from "jspdf";

const baseUrl = process.env.REACT_APP_BASE_URL;
const AdminTenantList = () => {
  const [tenants, setTenants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedTenant, setSelectedTenant] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [previewDoc, setPreviewDoc] = useState(null);
  const [openGuestsDialog, setOpenGuestsDialog] = useState(false);
  const [tenantGuests, setTenantGuests] = useState([]);
  const [openQRDialog, setOpenQRDialog] = useState(false);
  const [qrData, setQrData] = useState([]);
  const printRef = useRef();

  useEffect(() => {
    fetchTenants();
  }, []);

  const fetchTenants = async () => {
    try {
      const response = await axiosInstance.get("/tenants");
      setTenants(response.data);
    } catch (error) {
      console.error("Error fetching tenants:", error);
    }
    setLoading(false);
  };

  const handleOpenPreview = (docUrl) => {
    setPreviewDoc(docUrl);
    setOpenPreview(true);
  };

  const fetchTenantDocuments = async (tenantId) => {
    try {
      const response = await axiosInstance.get(`/tenantsDocuments/${tenantId}/documents`);
      setDocuments(response.data);
      setSelectedTenant(tenantId);
      setOpenDialog(true);
    } catch (error) {
      console.error("Error fetching documents:", error);
    }
  };

  const fetchTenantGuests = async (tenantId) => {
    try {
      const response = await axiosInstance.get(`/tenants/${tenantId}/guests`);
      setTenantGuests(response.data);
      setOpenGuestsDialog(true);
    } catch (error) {
      console.error("Error fetching tenant guests:", error);
    }
  };

  const handleReviewDocument = async (documentId, status) => {
    try {
      await axiosInstance.put(`/tenantsDocuments/review/${documentId}`, { estado: status });
      setDocuments((prev) =>
        prev.map((doc) => (doc.id === documentId ? { ...doc, estado: status } : doc))
      );
      fetchTenants(); // Refrescar lista de inquilinos
    } catch (error) {
      console.error("Error updating document status:", error);
    }
  };
  const fetchTenantQRs = async (tenantId) => {
    try {
      const response = await axiosInstance.get(`/tenants/${tenantId}`);
      const tenant = response.data;

      // Combinar inquilino y acompañantes en un solo array
      const allPeople = [{ ...tenant }, ...tenant.TenantGuests];

      const qrPromises = allPeople.map(async (person) => {
        const qrData = JSON.stringify({ token: person.token || "Sin Token" });
        const qrCode = await QRCode.toDataURL(qrData);
        return { ...person, qrCode };
      });

      const qrResults = await Promise.all(qrPromises);
      setQrData(qrResults);
      setOpenQRDialog(true);
    } catch (error) {
      console.error("Error fetching tenant QR codes:", error);
    }
  };

  //   const handlePrint = useReactToPrint({
  //     content: () => printRef.current,
  //     documentTitle: "QRs_Tenant",
  //   });
  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Administración de Inquilinos
      </Typography>

      {loading ? (
        <CircularProgress sx={{ display: "block", mx: "auto", mt: 4 }} />
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <b>Nombre</b>
                </TableCell>
                <TableCell>
                  <b>Documento</b>
                </TableCell>
                <TableCell>
                  <b>Email</b>
                </TableCell>
                <TableCell>
                  <b>Telefono</b>
                </TableCell>
                <TableCell>
                  <b>Lote</b>
                </TableCell>
                <TableCell>
                  <b>Propietario</b>
                </TableCell>
                <TableCell>
                  <b>Estado</b>
                </TableCell>

                <TableCell>
                  <b>Acciones</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tenants.map((tenant) => (
                <TableRow key={tenant.id}>
                  <TableCell>{tenant.nombre}</TableCell>
                  <TableCell>
                    {tenant.tipoDocumento} - {tenant.numeroDocumento}
                  </TableCell>
                  <TableCell>{tenant.email}</TableCell>
                  <TableCell>{tenant.telefono}</TableCell>
                  <TableCell>
                    Lote {tenant.Lote?.lote} - Mza {tenant.Lote?.manzana}
                  </TableCell>
                  <TableCell>Propietario {tenant.propietario?.nombre}</TableCell>
                  <TableCell>
                    <Chip
                      label={tenant.estado}
                      color={
                        tenant.estado === "pendiente"
                          ? "warning"
                          : tenant.estado === "activo"
                          ? "success"
                          : "error"
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      startIcon={<Visibility />}
                      onClick={() => fetchTenantDocuments(tenant.id)}
                    >
                      Ver Documentos
                    </Button>
                    <Button
                      variant="outlined"
                      startIcon={<People />}
                      onClick={() => fetchTenantGuests(tenant.id)}
                    >
                      Ver Acompañantes
                    </Button>
                    <Button
                      variant="outlined"
                      startIcon={<QrCode />}
                      onClick={() => fetchTenantQRs(tenant.id)}
                    >
                      Generar QR
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* Modal para ver documentos */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)} fullWidth maxWidth="md">
        <DialogTitle>Documentos del Inquilino</DialogTitle>
        <DialogContent dividers>
          {documents.length > 0 ? (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <b>Tipo de Documento</b>
                    </TableCell>
                    <TableCell>
                      <b>Archivo</b>
                    </TableCell>
                    <TableCell>
                      <b>Estado</b>
                    </TableCell>
                    <TableCell>
                      <b>Acciones</b>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {documents.map((doc) => (
                    <TableRow key={doc.id}>
                      <TableCell>{doc.tipoDocumento}</TableCell>
                      <TableCell>
                        {doc.archivo.endsWith(".pdf") ? (
                          <Button onClick={() => handleOpenPreview(`${baseUrl}${doc.archivo}`)}>
                            <Typography variant="body2" color="primary">
                              Ver PDF
                            </Typography>
                          </Button>
                        ) : (
                          <img
                            src={`${baseUrl}${doc.archivo}`}
                            alt="Documento"
                            style={{
                              width: "100px",
                              height: "100px",
                              objectFit: "cover",
                              borderRadius: "5px",
                              cursor: "pointer",
                            }}
                            onClick={() => handleOpenPreview(`${baseUrl}${doc.archivo}`)}
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        <Chip
                          label={doc.estado}
                          color={
                            doc.estado === "pendiente"
                              ? "warning"
                              : doc.estado === "aprobado"
                              ? "success"
                              : "error"
                          }
                        />
                      </TableCell>
                      <TableCell>
                        {doc.estado === "pendiente" && (
                          <>
                            <IconButton
                              color="success"
                              onClick={() => handleReviewDocument(doc.id, "aprobado")}
                            >
                              <CheckCircle />
                            </IconButton>
                            <IconButton
                              color="error"
                              onClick={() => handleReviewDocument(doc.id, "rechazado")}
                            >
                              <Cancel />
                            </IconButton>
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography>No hay documentos disponibles.</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cerrar</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openPreview} onClose={() => setOpenPreview(false)} fullWidth maxWidth="lg">
        <DialogContent>
          {previewDoc?.endsWith(".pdf") ? (
            <iframe
              src={previewDoc}
              width="100%"
              height="600px"
              style={{ border: "none" }}
            ></iframe>
          ) : (
            <img src={previewDoc} alt="Documento" style={{ width: "100%", height: "auto" }} />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenPreview(false)}>Cerrar</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openGuestsDialog}
        onClose={() => setOpenGuestsDialog(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Acompañantes del Inquilino</DialogTitle>
        <DialogContent dividers>
          {tenantGuests.length > 0 ? (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <b>Nombre</b>
                    </TableCell>
                    <TableCell>
                      <b>Documento</b>
                    </TableCell>
                    <TableCell>
                      <b>Teléfono</b>
                    </TableCell>
                    <TableCell>
                      <b>Email</b>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tenantGuests.map((guest) => (
                    <TableRow key={guest.id}>
                      <TableCell>{guest.nombre}</TableCell>
                      <TableCell>
                        {guest.tipoDocumento} - {guest.numeroDocumento}
                      </TableCell>
                      <TableCell>{guest.telefono}</TableCell>
                      <TableCell>{guest.email || "No registrado"}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography>No hay acompañantes registrados.</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenGuestsDialog(false)}>Cerrar</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openQRDialog} onClose={() => setOpenQRDialog(false)} fullWidth maxWidth="md">
        <DialogTitle>QR Codes del Inquilino y Acompañantes</DialogTitle>
        <DialogContent dividers ref={printRef}>
          {qrData.length > 0 ? (
            <Grid container spacing={2}>
              {qrData.map((person) => (
                <Grid item xs={12} sm={6} md={4} key={person.id}>
                  <Paper sx={{ p: 2, textAlign: "center" }}>
                    <Typography variant="h6">{person.nombre}</Typography>
                    <Typography variant="body2">
                      {person.tipoDocumento}: {person.numeroDocumento}
                    </Typography>
                    <img
                      src={person.qrCode}
                      alt={`QR ${person.nombre}`}
                      style={{ width: 150, height: 150 }}
                    />
                  </Paper>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Typography>No hay QRs disponibles.</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenQRDialog(false)}>Cerrar</Button>
          {/* <Button variant="contained" startIcon={<Print />} onClick={handlePrint}>
            Imprimir / Descargar PDF
          </Button> */}
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AdminTenantList;
