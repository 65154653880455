import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Chip,
} from "@mui/material";
import axiosInstance from "../../axiosInstance";

const TenantEntryHistory = () => {
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchEntryLogs();
  }, []);

  const fetchEntryLogs = async () => {
    try {
      const response = await axiosInstance.get("/tenant-entries/history");
      setLogs(response.data);
    } catch (error) {
      console.error("Error fetching entry logs:", error);
    }
    setLoading(false);
  };

  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Historial de Entradas y Salidas
      </Typography>

      {loading ? (
        <CircularProgress sx={{ display: "block", mx: "auto", mt: 4 }} />
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <b>Nombre</b>
                </TableCell>
                <TableCell>
                  <b>Teléfono</b>
                </TableCell>
                <TableCell>
                  <b>Lote</b>
                </TableCell>
                <TableCell>
                  <b>Propietario</b>
                </TableCell>
                <TableCell>
                  <b>Acción</b>
                </TableCell>
                <TableCell>
                  <b>Fecha y Hora</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {logs.map((log) => (
                <TableRow key={log.id}>
                  <TableCell>{log.Tenant?.nombre || log.TenantGuest?.nombre}</TableCell>
                  <TableCell>{log.Tenant?.telefono || log.TenantGuest?.telefono}</TableCell>
                  <TableCell>
                    {log.Tenant?.Lote
                      ? `Lote ${log.Tenant.Lote.lote} - Mza ${log.Tenant.Lote.manzana}`
                      : "N/A"}
                  </TableCell>
                  <TableCell>{log.Tenant?.propietario?.username || "N/A"}</TableCell>
                  <TableCell>
                    <Chip
                      label={log.action}
                      color={log.action === "Entrada" ? "success" : "error"}
                    />
                  </TableCell>
                  <TableCell>{new Date(log.createdAt).toLocaleString()}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default TenantEntryHistory;
