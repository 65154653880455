import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Paper,
  TextField,
  Button,
  Grid,
  IconButton,
  MenuItem,
  CircularProgress,
  Chip,
} from "@mui/material";
import { Add, Delete, CloudUpload, Visibility } from "@mui/icons-material";
import { useForm, Controller } from "react-hook-form";
import axiosInstance from "../../axiosInstance";
import { useDropzone } from "react-dropzone";
import DocumentTypeModal from "./components/documentsModal";
import { Snackbar, Alert } from "@mui/material";
const baseUrl = process.env.REACT_APP_BASE_URL;

const EditTenant = () => {
  const { tenantId } = useParams();
  const navigate = useNavigate();
  const { control, handleSubmit, setValue } = useForm();
  const [loading, setLoading] = useState(true);
  const [acompanantes, setAcompanantes] = useState([]);
  const [saving, setSaving] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [newDocuments, setNewDocuments] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [currentFile, setCurrentFile] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  useEffect(() => {
    fetchTenantData();
    fetchTenantDocuments();
  }, []);

  const fetchTenantData = async () => {
    try {
      const response = await axiosInstance.get(`/tenants/${tenantId}`);
      const tenant = response.data;

      Object.keys(tenant).forEach((key) => setValue(key, tenant[key]));

      setAcompanantes(tenant.TenantGuests || []);
      setDocuments(tenant.documents || []);
    } catch (error) {
      console.error("Error fetching tenant data:", error);
    }
    setLoading(false);
  };

  const fetchTenantDocuments = async () => {
    try {
      const response = await axiosInstance.get(`/tenantsDocuments/${tenantId}/documents`);
      setDocuments(response.data);
    } catch (error) {
      console.error("Error fetching documents:", error);
    }
  };

  const handleDocumentTypeSubmit = (tipoDocumento) => {
    if (currentFile) {
      setNewDocuments((prev) => [...prev, { file: currentFile, tipoDocumento }]);
      setCurrentFile(null);
      setOpenModal(false);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*, .pdf",
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        setCurrentFile(acceptedFiles[0]);
        setOpenModal(true);
      }
    },
  });
  const removeNewDocument = (index) => {
    setNewDocuments(newDocuments.filter((_, i) => i !== index));
  };
  const agregarAcompanante = () => {
    setAcompanantes([
      ...acompanantes,
      { nombre: "", tipoDocumento: "CI", numeroDocumento: "", telefono: "", email: "" },
    ]);
  };

  const actualizarAcompanante = (index, field, value) => {
    const nuevosAcompanantes = [...acompanantes];
    nuevosAcompanantes[index][field] = value;
    setAcompanantes(nuevosAcompanantes);
  };

  const eliminarAcompanante = (index) => {
    setAcompanantes(acompanantes.filter((_, i) => i !== index));
  };

  const eliminarDocumento = async (documentId) => {
    try {
      await axiosInstance.delete(`/tenantsDocuments/delete/${documentId}`);
      setDocuments(documents.filter((doc) => doc.id !== documentId));
    } catch (error) {
      console.error("Error deleting document:", error);
    }
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setNewDocuments([...newDocuments, ...files]);
  };

  const onSubmit = async (data) => {
    setSaving(true);
    try {
      await axiosInstance.put(`/tenants/update/${tenantId}`, {
        ...data,
        acompanantes,
      });

      if (newDocuments.length > 0) {
        const formData = new FormData();
        formData.append("tenantId", tenantId);

        newDocuments.forEach(({ file, tipoDocumento }) => {
          formData.append("documentos", file);
          formData.append("tiposDocumento", tipoDocumento);
        });

        await axiosInstance.post(`/tenantsDocuments/upload`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
      }

      setSnackbarMessage("¡Inquilino actualizado con éxito!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      navigate("/inquilinos");
    } catch (error) {
      setSnackbarMessage(error.response?.data?.error || "Error al actualizar inquilino");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
    setSaving(false);
  };

  if (loading) return <CircularProgress sx={{ display: "block", mx: "auto", mt: 4 }} />;

  return (
    <Paper elevation={3} sx={{ p: 4, maxWidth: 700, mx: "auto", mt: 4 }}>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} variant="filled">
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Typography variant="h4" align="center" gutterBottom>
        Editar Inquilino
      </Typography>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="nombre"
          control={control}
          defaultValue=""
          render={({ field }) => <TextField {...field} label="Nombre" fullWidth margin="normal" />}
        />

        <Controller
          name="tipoDocumento"
          control={control}
          defaultValue="CI"
          render={({ field }) => (
            <TextField {...field} select label="Tipo de Documento" fullWidth margin="normal">
              {["CI", "Pasaporte", "RUC"].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          )}
        />

        <Controller
          name="numeroDocumento"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField {...field} label="Número de Documento" fullWidth margin="normal" />
          )}
        />

        <Controller
          name="telefono"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField {...field} label="Teléfono" fullWidth margin="normal" />
          )}
        />

        <Controller
          name="email"
          control={control}
          defaultValue=""
          render={({ field }) => <TextField {...field} label="Email" fullWidth margin="normal" />}
        />

        <Controller
          name="fechaInicio"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              label="Fecha de Inicio"
              type="date"
              fullWidth
              margin="normal"
              InputLabelProps={{ shrink: true }}
            />
          )}
        />

        <Controller
          name="fechaFin"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              label="Fecha de Fin"
              type="date"
              fullWidth
              margin="normal"
              InputLabelProps={{ shrink: true }}
            />
          )}
        />

        <Typography variant="h6" sx={{ mt: 4 }}>
          Acompañantes
        </Typography>
        {acompanantes.map((acompanante, index) => (
          <Paper elevation={2} sx={{ p: 2, mb: 2 }} key={index}>
            <Grid container spacing={2} direction="column">
              <Grid item>
                <TextField
                  label="Nombre"
                  fullWidth
                  value={acompanante.nombre}
                  onChange={(e) => actualizarAcompanante(index, "nombre", e.target.value)}
                />
              </Grid>
              <Grid item>
                <TextField
                  select
                  label="Tipo de Documento"
                  fullWidth
                  value={acompanante.tipoDocumento}
                  onChange={(e) => actualizarAcompanante(index, "tipoDocumento", e.target.value)}
                >
                  {["CI", "Pasaporte"].map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item>
                <TextField
                  label="Número de Documento"
                  fullWidth
                  value={acompanante.numeroDocumento}
                  onChange={(e) => actualizarAcompanante(index, "numeroDocumento", e.target.value)}
                />
              </Grid>
              <Grid item>
                <TextField
                  label="Teléfono"
                  fullWidth
                  value={acompanante.telefono}
                  onChange={(e) => actualizarAcompanante(index, "telefono", e.target.value)}
                />
              </Grid>
              <Grid item>
                <TextField
                  label="Email"
                  fullWidth
                  value={acompanante.email}
                  onChange={(e) => actualizarAcompanante(index, "email", e.target.value)}
                />
              </Grid>
              <Grid item textAlign="right">
                <IconButton onClick={() => eliminarAcompanante(index)} color="error">
                  <Delete />
                </IconButton>
              </Grid>
            </Grid>
          </Paper>
        ))}

        <Button variant="contained" onClick={agregarAcompanante} startIcon={<Add />}>
          Agregar Acompañante
        </Button>

        <Typography variant="h6" sx={{ mt: 4 }}>
          Documentos
        </Typography>
        {documents.map((doc) => (
          <Box key={doc.id} display="flex" alignItems="center" justifyContent="space-between">
            <Chip label={doc.tipoDocumento} />
            <Button href={`${baseUrl}${doc.archivo}`} target="_blank" startIcon={<Visibility />}>
              Ver
            </Button>
            <IconButton onClick={() => eliminarDocumento(doc.id)} color="error">
              <Delete />
            </IconButton>
          </Box>
        ))}

        <Box
          {...getRootProps()}
          sx={{
            p: 3,
            border: "2px dashed gray",
            textAlign: "center",
            cursor: "pointer",
            mb: 3,
          }}
        >
          <input {...getInputProps()} />
          <CloudUpload sx={{ fontSize: 50, color: "gray" }} />
          <Typography>Arrastra y suelta archivos aquí o haz clic para subir</Typography>
        </Box>

        {newDocuments.length > 0 && (
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle1">Archivos a subir:</Typography>
            {newDocuments.map((doc, index) => (
              <Box
                key={index}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                sx={{ mb: 1 }}
              >
                <Typography>
                  {doc.file.name} - {doc.tipoDocumento}
                </Typography>
                <IconButton onClick={() => removeNewDocument(index)} color="error">
                  <Delete />
                </IconButton>
              </Box>
            ))}
          </Box>
        )}

        <Box sx={{ mt: 3, textAlign: "center" }}>
          <Button type="submit" variant="contained" color="primary" disabled={saving}>
            {saving ? "Guardando..." : "Guardar Cambios"}
          </Button>
        </Box>
      </form>

      <DocumentTypeModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        onSubmit={handleDocumentTypeSubmit}
      />
    </Paper>
  );
};

export default EditTenant;
