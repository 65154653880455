import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  Button,
  Chip,
  TextField,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  LinearProgress,
  DialogContentText,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Cancel, Refresh, CalendarToday, Download } from "@mui/icons-material";
import axiosInstance from "../../axiosInstance";
import { format, parseISO } from "date-fns";
import moment from "moment";
import esLocale from "date-fns/locale/es";
import * as XLSX from "xlsx";
import AdminReservationModal from "./components/AdminReservationModal";

const AdminReservations = () => {
  const [state, setState] = useState({
    reservations: [],
    loading: true,
    error: null,
    filters: {
      status: "",
      date: "",
    },
    pagination: {
      page: 1,
      pageSize: 10,
      total: 0,
    },
    cancelDialogOpen: false,
    selectedReservation: null,
  });
  const [reservationModalOpen, setReservationModalOpen] = useState(false);
  const [reservations, setReservations] = useState([]);
  const formatDate = (dateString) => {
    try {
      if (!dateString) return "Fecha no disponible";

      // Crear fecha en zona horaria local
      const [year, month, day] = dateString.split("-");
      const localDate = new Date(year, month - 1, day); // Los meses en JS son 0-indexed

      return format(localDate, "dd/MM/yyyy", { locale: esLocale });
    } catch (error) {
      console.error("Error formateando fecha:", dateString, error);
      return "Fecha inválida";
    }
  };

  const columns = [
    {
      field: "date",
      headerName: "Fecha",
      width: 150,
      valueFormatter: (params) => formatDate(params),
    },
    {
      field: "startTime",
      headerName: "Horario",
      width: 150,
      valueGetter: (params) => {
        const start = params.split(":").slice(0, 2).join(":") || "--:--";
        return `${start} `;
      },
    },
    {
      field: "endTime",
      headerName: "Horario",
      width: 150,
      valueGetter: (params) => {
        const end = params?.split(":").slice(0, 2).join(":") || "--:--";
        return `${end}`;
      },
    },
    {
      field: "Court",
      headerName: "Cancha",
      width: 200,
      valueGetter: (params) => params.name || "Cancha no especificada",
    },
    {
      field: "User",
      headerName: "Usuario",
      width: 200,
      valueGetter: (params) => params.username || "Usuario anónimo",
    },
    {
      field: "status",
      headerName: "Estado",
      width: 130,
      renderCell: (params) => (
        <Chip
          label={params.value}
          color={
            params.value === "confirmed"
              ? "success"
              : params.value === "cancelled"
              ? "error"
              : "warning"
          }
          size="small"
        />
      ),
    },
    {
      field: "actions",
      headerName: "Acciones",
      width: 150,
      renderCell: (params) => {
        // Verificar que no esté cancelada y que el horario final no haya pasado
        const isCancelled = params.row.status === "cancelled";
        const endDateTime = new Date(`${params.row.date}T${params.row.endTime}`);
        const isPast = endDateTime < new Date();

        return !isCancelled && !isPast ? (
          <Button
            variant="outlined"
            color="error"
            startIcon={<Cancel />}
            onClick={() => handleCancelClick(params.row)}
            size="small"
          >
            Cancelar
          </Button>
        ) : null;
      },
    },
  ];

  useEffect(() => {
    fetchReservations();
    fetchAllReservations();
  }, [state.pagination.page, state.filters]);

  const fetchAllReservations = async () => {
    try {
      const response = await axiosInstance.get("/reservations/all");
      setReservations(response.data.reservas);
    } catch (error) {
      console.error("Error fetching reservations:", error);
    }
  };
  const fetchReservations = async () => {
    try {
      setState((prev) => ({ ...prev, loading: true }));
      const response = await axiosInstance.get("/reservations", {
        params: {
          page: state.pagination.page,
          pageSize: state.pagination.pageSize,
          ...state.filters,
        },
      });

      const safeData = response.data.data.map((item) => ({
        id: item.id,
        date: item.date,
        startTime: item.startTime,
        endTime: item.endTime,
        status: item.status,
        Court: item.Court,
        User: item.User,
      }));

      setState((prev) => ({
        ...prev,
        reservations: safeData,
        pagination: { ...prev.pagination, total: response.data.total },
        loading: false,
      }));
    } catch (error) {
      setState((prev) => ({
        ...prev,
        error: "Error al cargar reservaciones",
        loading: false,
      }));
    }
  };

  const handleCancelClick = (reservation) => {
    setState((prev) => ({
      ...prev,
      cancelDialogOpen: true,
      selectedReservation: reservation,
    }));
  };

  const confirmCancel = async () => {
    try {
      await axiosInstance.put(`/reservations/${state.selectedReservation.id}/cancel`);
      fetchReservations();
      setState((prev) => ({
        ...prev,
        cancelDialogOpen: false,
        selectedReservation: null,
      }));
    } catch (error) {
      setState((prev) => ({
        ...prev,
        error: "Error al cancelar la reservación",
      }));
    }
  };

  // Función para exportar los datos

  const exportToExcel = () => {
    // Aplanar los datos para los campos `Court` y `User`
    console.log(reservations);

    const flattenedReservations = reservations.map((reservation) => ({
      date: reservation.date,
      startTime: reservation.startTime,
      endTime: reservation.endTime,
      status: reservation.status,
      Court: reservation.Court.name, // Nombre de la cancha
      User: reservation.User.username, // Nombre del usuario
    }));

    // Crear la hoja de trabajo con los datos aplanados
    const worksheet = XLSX.utils.json_to_sheet(flattenedReservations);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Reservaciones");

    // Generar y descargar el archivo Excel
    XLSX.writeFile(workbook, "reservaciones.xlsx");
  };

  return (
    <Box sx={{ p: 3, height: "calc(100vh - 64px)" }}>
      <Card sx={{ mb: 2 }}>
        <CardContent>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={8} md={4}>
              <Typography variant="h5" gutterBottom>
                <CalendarToday sx={{ verticalAlign: "middle", mr: 1 }} />
                Gestión de Reservaciones
              </Typography>
            </Grid>
            <Grid item xs={4} md={4}>
              <Button
                variant="contained"
                onClick={() => setReservationModalOpen(true)}
                sx={{ mb: 2 }}
              >
                Crear Reserva
              </Button>
            </Grid>

            <Grid item xs={6} md={3}>
              <TextField
                type="date"
                fullWidth
                label="Filtrar por fecha"
                InputLabelProps={{ shrink: true }}
                value={state.filters.date}
                onChange={(e) =>
                  setState((prev) => ({
                    ...prev,
                    filters: { ...prev.filters, date: e.target.value },
                  }))
                }
              />
            </Grid>

            <Grid item xs={6} md={3}>
              <Select
                fullWidth
                value={state.filters.status}
                onChange={(e) =>
                  setState((prev) => ({
                    ...prev,
                    filters: { ...prev.filters, status: e.target.value },
                  }))
                }
                displayEmpty
              >
                <MenuItem value="">Todos los estados</MenuItem>
                <MenuItem value="confirmed">Confirmadas</MenuItem>
                <MenuItem value="cancelled">Canceladas</MenuItem>
                <MenuItem value="pending">Pendientes</MenuItem>
              </Select>
            </Grid>

            <Grid item xs={12} md={2}>
              <Button
                fullWidth
                variant="contained"
                startIcon={<Refresh />}
                onClick={fetchReservations}
              >
                Actualizar
              </Button>
            </Grid>
            <Grid item xs={12} md={2}>
              <Button
                fullWidth
                variant="contained"
                startIcon={<Download />}
                onClick={exportToExcel} // Llama a la función para exportar
              >
                Exportar a Excel
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <div style={{ height: "70%", width: "100%" }}>
        <DataGrid
          rows={state.reservations}
          columns={columns}
          pagination
          paginationMode="server"
          rowCount={state.pagination.total}
          pageSize={state.pagination.pageSize}
          page={state.pagination.page - 1}
          onPageChange={(newPage) =>
            setState((prev) => ({
              ...prev,
              pagination: { ...prev.pagination, page: newPage + 1 },
            }))
          }
          loading={state.loading}
          components={{
            LoadingOverlay: LinearProgress,
          }}
          localeText={{
            noRowsLabel: "No hay reservaciones registradas",
          }}
        />
      </div>

      <Dialog
        open={state.cancelDialogOpen}
        onClose={() => setState((prev) => ({ ...prev, cancelDialogOpen: false }))}
      >
        <DialogTitle>Confirmar Cancelación</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {state.selectedReservation &&
              `
              ¿Está seguro de cancelar la reservación de 
              ${state.selectedReservation.Court?.name || "Cancha desconocida"} el 
              ${formatDate(state.selectedReservation.date)} a las 
              ${state.selectedReservation.startTime?.split(":").slice(0, 2).join(":") || "--:--"}?
            `}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setState((prev) => ({ ...prev, cancelDialogOpen: false }))}>
            Cancelar
          </Button>
          <Button onClick={confirmCancel} color="error" variant="contained">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
      <AdminReservationModal
        open={reservationModalOpen}
        onClose={() => setReservationModalOpen(false)}
        courtId="1" // Pasa el ID de la cancha seleccionada
        date={moment()} // Pasa la fecha seleccionada
      />
    </Box>
  );
};

export default AdminReservations;
