import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Chip,
  Button,
  Divider,
  Pagination,
  Skeleton,
  Alert,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Snackbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { Cancel, SportsSoccer, Schedule, CalendarToday } from "@mui/icons-material";
import axiosInstance from "../../axiosInstance";
import { format } from "date-fns";
import esLocale from "date-fns/locale/es";

const ReservationHistory = () => {
  const [state, setState] = useState({
    reservations: [],
    loading: true,
    error: null,
    page: 1,
    pageSize: 5,
    totalPages: 1,
    sort: "DESC",
    cancelDialogOpen: false,
    selectedReservation: null,
    snackbar: { open: false, message: "", severity: "success" },
  });

  useEffect(() => {
    fetchReservations();
  }, [state.page, state.sort]);

  const fetchReservations = async () => {
    try {
      const response = await axiosInstance.get("/reservations/user", {
        params: {
          page: state.page,
          pageSize: state.pageSize,
          sort: state.sort,
        },
      });

      setState((prev) => ({
        ...prev,
        reservations: response.data.data,
        totalPages: response.data.meta.totalPages,
        loading: false,
      }));
    } catch (error) {
      setState((prev) => ({
        ...prev,
        error: "Error al cargar las reservas",
        loading: false,
      }));
    }
  };

  const handleCancelClick = (reservation) => {
    setState((prev) => ({
      ...prev,
      cancelDialogOpen: true,
      selectedReservation: reservation,
    }));
  };

  const confirmCancel = async () => {
    try {
      await axiosInstance.put(`/reservations/${state.selectedReservation.id}/cancel`);
      fetchReservations();
      showSnackbar("Reserva cancelada exitosamente", "success");
    } catch (error) {
      showSnackbar("Error al cancelar la reserva", "error");
    } finally {
      setState((prev) => ({ ...prev, cancelDialogOpen: false }));
    }
  };

  const showSnackbar = (message, severity) => {
    setState((prev) => ({
      ...prev,
      snackbar: { open: true, message, severity },
    }));
  };

  const handlePageChange = (event, value) => {
    setState((prev) => ({ ...prev, page: value }));
  };

  const handleSortChange = (event) => {
    setState((prev) => ({ ...prev, sort: event.target.value }));
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "confirmed":
        return "success";
      case "cancelled":
        return "error";
      default:
        return "warning";
    }
  };

  const formatDate = (dateString) => {
    try {
      if (!dateString) return "Fecha no disponible";

      // Crear fecha en zona horaria local
      const [year, month, day] = dateString.split("-");
      const localDate = new Date(year, month - 1, day); // Los meses en JS son 0-indexed

      return format(localDate, "dd/MM/yyyy", { locale: esLocale });
    } catch (error) {
      console.error("Error formateando fecha:", dateString, error);
      return "Fecha inválida";
    }
  };

  return (
    <Card elevation={3} sx={{ p: 2, borderRadius: 3 }}>
      <CardContent>
        <Grid container spacing={2} alignItems="center" sx={{ mb: 3 }}>
          <Grid item xs={12} md={6}>
            <Typography variant="h5" component="h2" sx={{ fontWeight: 600 }}>
              <SportsSoccer sx={{ verticalAlign: "middle", mr: 1 }} />
              Historial de Reservas
            </Typography>
          </Grid>

          <Grid item xs={12} md={6} sx={{ textAlign: { md: "right" } }}>
            <FormControl size="small" sx={{ minWidth: 120 }}>
              <InputLabel>Ordenar por</InputLabel>
              <Select value={state.sort} onChange={handleSortChange} label="Ordenar por">
                <MenuItem value="DESC">Más recientes primero</MenuItem>
                <MenuItem value="ASC">Más antiguas primero</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        {state.error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {state.error}
          </Alert>
        )}

        {state.loading ? (
          [...Array(state.pageSize)].map((_, index) => (
            <Skeleton
              key={index}
              variant="rectangular"
              height={100}
              sx={{ mb: 2, borderRadius: 2 }}
            />
          ))
        ) : state.reservations.length === 0 ? (
          <Alert severity="info" sx={{ mb: 2 }}>
            No tienes reservas registradas
          </Alert>
        ) : (
          state.reservations.map((reservation) => (
            <Card key={reservation.id} sx={{ mb: 2, position: "relative" }}>
              <CardContent>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={12} md={4}>
                    <Typography variant="h6" sx={{ fontWeight: 500 }}>
                      {reservation.Court.name}
                    </Typography>
                    <Chip
                      label={reservation.status}
                      color={getStatusColor(reservation.status)}
                      size="small"
                      sx={{ mt: 1 }}
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Typography variant="body2" color="text.secondary">
                      <CalendarToday sx={{ verticalAlign: "middle", mr: 1 }} />
                      {formatDate(reservation.date)}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      <Schedule sx={{ verticalAlign: "middle", mr: 1 }} />
                      {reservation.startTime} - {reservation.endTime}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={4} sx={{ textAlign: { md: "right" } }}>
                    {reservation.status === "confirmed" && (
                      <Button
                        variant="outlined"
                        color="error"
                        startIcon={<Cancel />}
                        onClick={() => handleCancelClick(reservation)}
                        sx={{ borderRadius: 5 }}
                      >
                        Cancelar Reserva
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
            </Card>
          ))
        )}

        {state.totalPages > 1 && (
          <Pagination
            count={state.totalPages}
            page={state.page}
            onChange={handlePageChange}
            color="primary"
            sx={{ mt: 3, display: "flex", justifyContent: "center" }}
          />
        )}
      </CardContent>

      {/* Diálogo de confirmación */}
      <Dialog
        open={state.cancelDialogOpen}
        onClose={() => setState((prev) => ({ ...prev, cancelDialogOpen: false }))}
      >
        <DialogTitle>Confirmar cancelación</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro que deseas cancelar la reserva para{" "}
            {state.selectedReservation?.Court.name} el {formatDate(state.selectedReservation?.date)}
            ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setState((prev) => ({ ...prev, cancelDialogOpen: false }))}>
            Volver
          </Button>
          <Button onClick={confirmCancel} color="error" variant="contained">
            Confirmar Cancelación
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar de notificaciones */}
      <Snackbar
        open={state.snackbar.open}
        autoHideDuration={4000}
        onClose={() =>
          setState((prev) => ({ ...prev, snackbar: { ...prev.snackbar, open: false } }))
        }
      >
        <Alert severity={state.snackbar.severity}>{state.snackbar.message}</Alert>
      </Snackbar>
    </Card>
  );
};

export default ReservationHistory;
