import React, { useState, useEffect } from "react";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Typography,
  Button,
  TextField,
  IconButton,
  Grid,
  Paper,
  CircularProgress,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
} from "@mui/material";
import { Add, Delete, CloudUpload } from "@mui/icons-material";
import { useForm, Controller } from "react-hook-form";
import { useDropzone } from "react-dropzone";
import axiosInstance from "../../axiosInstance"; // Asegúrate de que la ruta sea correcta
import DocumentTypeModal from "./components/documentsModal";
import { Snackbar, Alert } from "@mui/material";
const steps = ["Datos del Inquilino", "Acompañantes", "Subir Documentos"];

const TenantForm = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [acompanantes, setAcompanantes] = useState([]);
  const [archivos, setArchivos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lotes, setLotes] = useState([]);
  const [tenantData, setTenantData] = useState(null);
  const [documentos, setDocumentos] = useState([]); // Almacenar archivos con tipo de documento
  const [openModal, setOpenModal] = useState(false);
  const [currentFile, setCurrentFile] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  useEffect(() => {
    fetchLotes();
  }, []);

  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors },
  } = useForm();

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*, .pdf",
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        setCurrentFile(acceptedFiles[0]); // Guardar el archivo actual
        setOpenModal(true); // Abrir el modal
      }
    },
  });

  const handleDocumentTypeSubmit = (tipoDocumento) => {
    if (currentFile) {
      setDocumentos((prev) => [...prev, { file: currentFile, tipoDocumento }]);
      setCurrentFile(null); // Limpiar el archivo actual
    }
  };
  const fetchLotes = async () => {
    const response = await axiosInstance.get(`/lotes/getMyLotes`);
    setLotes(response.data);
  };

  const agregarAcompanante = () => {
    setAcompanantes([
      ...acompanantes,
      { nombre: "", tipoDocumento: "CI", numeroDocumento: "", telefono: "", email: "" },
    ]);
  };

  const actualizarAcompanante = (index, field, value) => {
    const nuevosAcompanantes = [...acompanantes];
    nuevosAcompanantes[index][field] = value;
    setAcompanantes(nuevosAcompanantes);
  };

  const eliminarAcompanante = (index) => {
    setAcompanantes(acompanantes.filter((_, i) => i !== index));
  };

  // 📌 Guarda los datos del inquilino en el estado, sin enviarlos aún
  // Modificar la función handleNext para validar antes de avanzar
  const handleNext = () => {
    if (activeStep === 0) {
      // Validar campos del primer paso
      const values = getValues();
      if (!values.nombre || !values.numeroDocumento || !values.loteId) {
        return alert("Completa todos los campos requeridos");
      }
      setTenantData(values);
    }

    setActiveStep((prev) => prev + 1);
  };

  // 📌 Envía todos los datos al backend solo en el último paso
  const onSubmit = async () => {
    // setLoading(true);
    // try {
    //   const tenantResponse = await axiosInstance.post("/tenants/create", {
    //     ...tenantData,
    //     acompanantes,
    //   });
    //   const tenantId = tenantResponse.data.tenant.id;
    //   if (documentos.length > 0) {
    //     const formData = new FormData();
    //     documentos.forEach(({ file, tipoDocumento }) => {
    //       formData.append("documentos", file);
    //       formData.append("tiposDocumento", tipoDocumento); // ✅ Enviar tipo de documento
    //     });
    //     await axiosInstance.post(`/tenantDocuments/upload/${tenantId}`, formData, {
    //       headers: { "Content-Type": "multipart/form-data" },
    //     });
    //   }
    //   alert("¡Inquilino registrado con éxito!");
    //   setTenantData(null);
    //   setAcompanantes([]);
    //   setArchivos([]);
    //   setDocumentos([]);
    //   setActiveStep(0);
    // } catch (error) {
    //   alert(error.response?.data?.error || "Error al registrar inquilino");
    // }
    // setLoading(false);
  };

  const handleFinalSubmit = async () => {
    setLoading(true);
    try {
      const totalPersonas = 1 + acompanantes.length; // Inquilino + acompañantes
      const documentosRequeridos = totalPersonas * 2;

      if (documentos.length < documentosRequeridos) {
        setSnackbarMessage(
          `Debes subir al menos 2 documentos por cada persona (${documentosRequeridos} en total).`
        );
        setSnackbarSeverity("warning");
        setSnackbarOpen(true);
        setLoading(false);
        return;
      }

      const tenantResponse = await axiosInstance.post("/tenants/create", {
        ...tenantData,
        acompanantes,
      });

      const tenantId = tenantResponse.data.tenant.id;

      if (documentos.length > 0) {
        const formData = new FormData();
        formData.append("tenantId", tenantId);

        documentos.forEach(({ file, tipoDocumento }) => {
          formData.append("documentos", file);
          formData.append("tiposDocumento", tipoDocumento);
        });

        await axiosInstance.post(`/tenantsDocuments/upload`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
      }

      setSnackbarMessage("¡Inquilino registrado con éxito!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);

      setTenantData(null);
      setAcompanantes([]);
      setDocumentos([]);
      setActiveStep(0);
    } catch (error) {
      setSnackbarMessage(error.response?.data?.error || "Error al registrar inquilino");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
    setLoading(false);
  };

  return (
    <Paper elevation={3} sx={{ p: 4, maxWidth: 700, mx: "auto", mt: 4 }}>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} variant="filled">
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Typography variant="h4" align="center" gutterBottom>
        Registro de Inquilino
      </Typography>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <Box sx={{ mt: 3 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {activeStep === 0 && (
            <>
              <Controller
                name="nombre"
                control={control}
                defaultValue=""
                rules={{ required: "Campo obligatorio" }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Nombre"
                    fullWidth
                    margin="normal"
                    error={!!errors.nombre}
                    helperText={errors.nombre?.message}
                  />
                )}
              />
              <Controller
                name="tipoDocumento"
                control={control}
                defaultValue="CI"
                rules={{ required: "Campo obligatorio" }}
                render={({ field }) => (
                  <TextField {...field} select label="Tipo de Documento" fullWidth margin="normal">
                    {["CI", "Pasaporte", "RUC"].map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
              <Controller
                name="numeroDocumento"
                control={control}
                defaultValue=""
                rules={{ required: "Campo obligatorio" }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Número de Documento"
                    fullWidth
                    margin="normal"
                    error={!!errors.numeroDocumento}
                    helperText={errors.numeroDocumento?.message}
                  />
                )}
              />
              <Controller
                name="telefono"
                control={control}
                defaultValue=""
                rules={{ required: "Campo obligatorio" }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Teléfono"
                    fullWidth
                    margin="normal"
                    error={!!errors.telefono}
                    helperText={errors.telefono?.message}
                  />
                )}
              />
              <Controller
                name="email"
                control={control}
                defaultValue=""
                rules={{
                  required: "Campo obligatorio",
                  pattern: {
                    value: /^\S+@\S+$/i,
                    message: "Email inválido",
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Email"
                    fullWidth
                    margin="normal"
                    error={!!errors.email}
                    helperText={errors.email?.message}
                  />
                )}
              />
              <Controller
                name="fechaInicio"
                control={control}
                defaultValue=""
                rules={{ required: "Campo obligatorio" }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Fecha de Inicio"
                    type="date"
                    fullWidth
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    error={!!errors.fechaInicio}
                    helperText={errors.fechaInicio?.message}
                  />
                )}
              />
              <Controller
                name="fechaFin"
                control={control}
                defaultValue=""
                rules={{ required: "Campo obligatorio" }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Fecha de Fin"
                    type="date"
                    fullWidth
                    margin="normal"
                    InputLabelProps={{ shrink: true }}
                    error={!!errors.fechaFin}
                    helperText={errors.fechaFin?.message}
                  />
                )}
              />
              <Controller
                name="loteId"
                control={control}
                defaultValue=""
                rules={{ required: "Campo obligatorio" }}
                render={({ field }) => (
                  <FormControl fullWidth margin="normal" error={!!errors.loteId}>
                    <InputLabel id="lote-select-label">Seleccionar Lote</InputLabel>
                    <Select {...field} labelId="lote-select-label" label="Seleccionar Lote">
                      {lotes?.map((loteItem) => (
                        <MenuItem key={loteItem.id} value={loteItem.id}>
                          Lote: {loteItem.lote} - Manzana: {loteItem.manzana}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.loteId && <FormHelperText>{errors.loteId.message}</FormHelperText>}
                  </FormControl>
                )}
              />
            </>
          )}

          {activeStep === 1 && (
            <>
              <Box>
                {acompanantes.map((acompanante, index) => (
                  <Paper elevation={2} sx={{ p: 2, mb: 2 }} key={index}>
                    <Grid container spacing={2} direction="column">
                      {/* Nombre */}
                      <Grid item>
                        <TextField
                          label="Nombre"
                          fullWidth
                          required
                          value={acompanante.nombre}
                          onChange={(e) => actualizarAcompanante(index, "nombre", e.target.value)}
                        />
                      </Grid>

                      {/* Tipo de Documento */}
                      <Grid item>
                        <TextField
                          select
                          label="Tipo de Documento"
                          fullWidth
                          required
                          value={acompanante.tipoDocumento}
                          onChange={(e) =>
                            actualizarAcompanante(index, "tipoDocumento", e.target.value)
                          }
                        >
                          {["CI", "Pasaporte"].map((option) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>

                      {/* Número de Documento */}
                      <Grid item>
                        <TextField
                          label="Número de Documento"
                          fullWidth
                          required
                          value={acompanante.numeroDocumento}
                          onChange={(e) =>
                            actualizarAcompanante(index, "numeroDocumento", e.target.value)
                          }
                        />
                      </Grid>

                      {/* Teléfono */}
                      <Grid item>
                        <TextField
                          label="Teléfono"
                          fullWidth
                          required
                          value={acompanante.telefono}
                          onChange={(e) => actualizarAcompanante(index, "telefono", e.target.value)}
                        />
                      </Grid>

                      {/* Email */}
                      <Grid item>
                        <TextField
                          label="Email"
                          fullWidth
                          value={acompanante.email}
                          onChange={(e) => actualizarAcompanante(index, "email", e.target.value)}
                        />
                      </Grid>

                      {/* Botón de eliminar */}
                      <Grid item textAlign="right">
                        <IconButton onClick={() => eliminarAcompanante(index)} color="error">
                          <Delete />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Paper>
                ))}

                <Button variant="contained" onClick={agregarAcompanante} startIcon={<Add />}>
                  Agregar Acompañante
                </Button>
              </Box>
            </>
          )}

          {activeStep === 2 && (
            <Box>
              {/* Texto descriptivo */}
              <Typography variant="h6" gutterBottom>
                Agregar documentos del inquilino y acompañantes
              </Typography>
              {/* <Typography variant="body2" color="textSecondary" gutterBottom>
                Sube los documentos necesarios, como identificación, contrato, etc.
              </Typography> */}

              {/* Área de subida de archivos */}
              <Box
                {...getRootProps()}
                sx={{
                  p: 3,
                  border: "2px dashed gray",
                  textAlign: "center",
                  cursor: "pointer",
                  mb: 3,
                }}
              >
                <input {...getInputProps()} />
                <CloudUpload sx={{ fontSize: 50, color: "gray" }} />
                <Typography>Arrastra y suelta archivos aquí o haz clic para subir</Typography>
              </Box>

              {/* Lista de documentos subidos */}
              {documentos.length > 0 && (
                <Box>
                  <Typography variant="subtitle1" gutterBottom>
                    Documentos subidos:
                  </Typography>
                  {documentos.map((doc, index) => (
                    <Paper
                      key={index}
                      elevation={2}
                      sx={{
                        p: 2,
                        mb: 2,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="body2">
                        {doc.file.name} - {doc.tipoDocumento}
                      </Typography>
                      <IconButton
                        color="error"
                        onClick={() => {
                          setDocumentos((prev) => prev.filter((_, i) => i !== index));
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </Paper>
                  ))}
                </Box>
              )}
            </Box>
          )}

          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}>
            <Button disabled={activeStep === 0} onClick={() => setActiveStep(activeStep - 1)}>
              Atrás
            </Button>

            {activeStep === steps.length - 1 ? (
              <Button variant="contained" onClick={handleFinalSubmit} disabled={loading}>
                {loading ? <CircularProgress size={24} /> : "Registrar"}
              </Button>
            ) : (
              <Button variant="contained" onClick={handleNext} disabled={loading} type="button">
                Siguiente
              </Button>
            )}
          </Box>
        </form>
      </Box>
      <DocumentTypeModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        onSubmit={handleDocumentTypeSubmit}
      />
    </Paper>
  );
};

export default TenantForm;
